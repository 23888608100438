/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { settings, settingsSlider } from './configs';
import {
    Container,
    Slider,
    Banner,
    Section,
    Headline,
    HowWorks,
    Card,
    ParticipateButton,
    Accordion,
    AccordionSummary,
    HotsitePreloader,
} from '@design-system';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Main, MainHowWorks, BoxContainer, ChevronDiv, MainSlider, CampaignWarning } from './styles';
import { Patterns } from '@components';
import { location, maxText, menu } from '@utils'
import { Unpublished } from '../common/unpublished';
import { useTheme } from '../../hooks/theming';
import { useCampaign } from '@hooks';
import { CONSTANTS } from '@constants/index';

const Hotsite: React.FC = () => {

    const { hotsite, app } = useTheme();
    const { campaigns } = useCampaign();

    const aliasCompany = CONSTANTS.ALIAS.COMPANY(location.getSubdomain() as string);
    const aliasProgramCode = CONSTANTS.ALIAS.PROGRAM_CODE(location.getFirstPathname() as string);

    const matches = window.matchMedia("(min-width: 900px)")

    const [questionId, setQuestionId] = React.useState('')

    const settingsBannersSlider = {
        ...settingsSlider,
        nextArrow: <button><I icon={['far', 'chevron-right']} size="3x" /></button>,
        prevArrow: <button><I icon={['far', 'chevron-left']} size="3x" /></button>,
    };

    const settingsCampaignSlider = {
        ...settings,
        nextArrow: <button><I icon={['far', 'chevron-right']} size="2x" color={
            hotsite?.theme?.styles.data?.activeCampaigns?.participateButtonBgColor
                ? hotsite?.theme?.styles.data?.activeCampaigns?.participateButtonBgColor
                : '#CCCCCC'
        } /></button>,
        prevArrow: <button><I icon={['far', 'chevron-left']} size="2x" color={
            hotsite?.theme?.styles.data?.activeCampaigns?.participateButtonBgColor
                ? hotsite?.theme?.styles.data?.activeCampaigns?.participateButtonBgColor
                : '#CCCCCC'
        } /></button>,
    };

    React.useEffect(() => {
        campaigns.load({
            websiteAliasCompany: aliasCompany,
            websiteAliasProgram: aliasProgramCode,
        })
        app.loadContactFormIsEnabled({
            websiteAliasCompany: aliasCompany,
            websiteAliasProgram: aliasProgramCode,
        })
    }, []);

    const loading = (
        !hotsite.theme.texts.isLoaded &&
        hotsite.theme.texts.status <= 200 &&
        !hotsite.theme.styles.isLoaded &&
        hotsite.theme.styles.status <= 200 &&
        !campaigns.list.isLoaded);

    const notPublished = (
        !hotsite.theme.texts.isLoaded &&
        hotsite.theme.texts.status >= 400 &&
        !hotsite.theme.styles.isLoaded &&
        hotsite.theme.styles.status >= 400);

    // const menu = (
    //     navData: NavDataProps[],
    //     contactFormIsEnabled: boolean,
    //     section1Visibility: "true" | "false" | undefined,
    // ) => {
    //     const tempMenu: NavDataProps[] = [];
    //     const dataMenu: NavDataProps[] = [];

    //     if (contactFormIsEnabled) {
    //         tempMenu.push(...navData);
    //     } else {
    //         tempMenu.push(...navData
    //             // Fale conosco id = 4
    //             .filter(({ id }) => id !== '4'));
    //     }

    //     if (section1Visibility !== 'false') {
    //         const filter = tempMenu.filter(({ link }) => link !== '#como-funciona');
    //         const newMenuItem = {
    //             id: '2',
    //             link: '#1',
    //             title: hotsite.theme.texts.data.section1.section1Headline.section1HeadlineText,
    //             _id: '666b3e7562f23fa6e7f391ba',
    //         };
    //         console.log('TEST MENU:', filter);

    //         dataMenu.push(newMenuItem, ...filter);
    //     }


    //     return dataMenu;
    // };

    // console.log('MENU', menu(hotsite.theme.texts.data.header.headerNav.headerNavData, app.contactForm.data.existsTalkToUs, hotsite.theme.texts.data.section1.section1Visibility));


    return (
        <Main>
            {loading && (<HotsitePreloader />)}
            {hotsite.theme.texts.isLoaded && hotsite.theme.styles.isLoaded && (
                <>
                    <Patterns.Header
                        custom={hotsite.theme.styles.data.headerHotsite}
                        theme={hotsite.theme.texts.data.themeName}
                        className='headerHotsite'
                        logo={`${hotsite.theme.texts.data.header.headerLogo}`}
                        navData={menu(
                            hotsite.theme.texts.data.header.headerNav.headerNavData,
                            '#como-funciona',
                            app.contactForm.data.existsTalkToUs,
                            hotsite.theme.texts.data.section1.section1Visibility,
                            hotsite.theme.texts.data.section1.section1Headline.section1HeadlineText,
                          )}
                        // navData={hotsite.theme.texts.data.section1.section1Visibility !== 'false' ? [
                        //     {
                        //         id: '0',
                        //         link: '#1',
                        //         title: hotsite.theme.texts.data.section1.section1Headline.section1HeadlineText
                        //     },
                        //     ...hotsite.theme.texts.data.header.headerNav.headerNavData.slice(1)
                        // ] : hotsite.theme.texts.data.header.headerNav.headerNavData.slice(1)}
                        buttonCreateAccount={hotsite.theme.texts.data.header.headerButtonCreateAccount}
                        buttonAccess={hotsite.theme.texts.data.header.headerButtonAccess}
                    />
                    <Slider className='main-slider' {...settingsBannersSlider}>
                        {hotsite.theme.texts.data.heroSlider
                            .map(({ id, src, srcMobile, link }) => (
                                <Banner
                                    key={id}
                                    src={`${location.filesPath(src)}`}
                                    srcMobile={`${location.filesPath(src)}`}
                                    link={link}
                                />
                            ))
                        }
                    </Slider>
                    {hotsite.theme.texts.data.section1.section1Visibility !== 'false' &&
                        <Section id='1'>
                            <Container>
                                <Headline
                                    color={hotsite.theme.styles.data.howItWorks.titleColor}
                                    className='headlineColor'>
                                    {hotsite.theme.texts.data.section1.section1Headline.section1HeadlineText}
                                </Headline>

                                <MainHowWorks className='mainHowItWorks'>
                                    {hotsite.theme.texts.data.section1.section1ComponentData.map(
                                        ({ section1ComponentDataId, section1ComponentDataTitle, section1ComponentDataSubtitle }, i) => {
                                            const index = i + 1;
                                            return (
                                                <BoxContainer className='boxContainer' key={section1ComponentDataId}>
                                                    <HowWorks
                                                        color={hotsite.theme.styles.data.howItWorks.boxTitleColor}
                                                        styleBox={{
                                                            boxColor: hotsite.theme.styles.data.howItWorks.boxColor,
                                                            labelColor: hotsite.theme.styles.data.howItWorks.boxLabelColor,
                                                        }}
                                                        step={index}
                                                        title={section1ComponentDataTitle}
                                                        subtitle={section1ComponentDataSubtitle} />

                                                    <ChevronDiv className='chevronDiv'>
                                                        <I
                                                            icon={['fas', 'chevron-double-right']}
                                                            style={{ height: '1.5rem', color: hotsite.theme.styles.data.howItWorks.iconColor }}
                                                            className="iconColor" />
                                                    </ChevronDiv>
                                                </BoxContainer>
                                            )
                                        })}
                                </MainHowWorks>
                            </Container>
                        </Section>
                    }
                    <Section id='campanhas'>
                        <Container>
                            <Headline
                                color={hotsite.theme.styles.data.activeCampaigns.titleColor}
                                className='headlineColor'>
                                {hotsite.theme.texts.data.section2.section2Headline.section2HeadlineText}
                            </Headline>
                            <MainSlider className='mainSlider'>

                                {campaigns.list.isLoaded ?
                                    (
                                        (matches.matches && campaigns.list.data.length < 3) ?
                                            <div style={{
                                                display: 'flex',
                                                gap: '16px',
                                                justifyContent: 'center',
                                            }}>
                                                {campaigns.list.data.length > 0 ? campaigns.list.data.map(({
                                                    _id,
                                                    name,
                                                    description,
                                                    websiteAlias,
                                                    websiteCustomization,
                                                }) => {
                                                    return (
                                                        <Card
                                                            key={_id}
                                                            logo={'/files/' + websiteCustomization.logo}
                                                            banner={websiteCustomization?.hotsite?.heroSlider[0]?.srcMobile ?? ''}
                                                            title={name}
                                                            description={maxText({
                                                                value: description,
                                                                maxChars: 108
                                                            })}
                                                        >
                                                            <ParticipateButton
                                                                style={{
                                                                    color: hotsite.theme.styles.data.activeCampaigns.participateButtonColor,
                                                                    bgColor: hotsite.theme.styles.data.activeCampaigns.participateButtonBgColor,
                                                                }}
                                                                link={`${websiteAlias}`} />
                                                        </Card>
                                                    )
                                                }) :
                                                    <CampaignWarning>
                                                        <span>No momento não há campanhas ativas</span>
                                                    </CampaignWarning>
                                                }
                                            </div> :
                                            <Slider {...settingsCampaignSlider} className='campaign-slider'>
                                                {campaigns.list.data.map(({ _id, name, description, websiteAlias, websiteCustomization }) => {
                                                    return (
                                                        <Card
                                                            key={_id}
                                                            logo={'/files/' + websiteCustomization.logo}
                                                            banner={websiteCustomization?.hotsite?.heroSlider[0]?.srcMobile ?? ''}
                                                            title={maxText({
                                                                value: name,
                                                                maxChars: 30
                                                            })}
                                                            description={maxText({
                                                                value: description,
                                                                maxChars: 80
                                                            })}>
                                                            <ParticipateButton
                                                                style={{
                                                                    color: hotsite.theme.styles.data.activeCampaigns.participateButtonColor,
                                                                    bgColor: hotsite.theme.styles.data.activeCampaigns.participateButtonBgColor,
                                                                }}
                                                                link={`${websiteAlias}`} />
                                                        </Card>
                                                    )
                                                })}
                                            </Slider>
                                    )
                                    :
                                    <CampaignWarning>
                                        <span>No momento não há campanhas ativas</span>
                                    </CampaignWarning>
                                }
                            </MainSlider>
                        </Container>
                    </Section>
                    <Section className='last' id="duvidas-frequentes">
                        <Container>
                            <Headline
                                color={hotsite.theme.styles.data.faq.titleColor}
                                className='headlineColor'>
                                {hotsite.theme.texts.data.faq.faqTitle.faqTitleText}
                            </Headline>
                            {hotsite.theme.texts.data.faq.faqContent.map(({ id, title, questions }) => {
                                return (
                                    <Accordion
                                        key={`${id}`}
                                        title={title}
                                        className={'accordionHotsite'}
                                        color={hotsite.theme.styles.data.faq.toggleIconColor}
                                    >
                                        {questions.map(({ id, title, text }, i) => {
                                            const index = i + 1;
                                            return (
                                                <AccordionSummary
                                                    onClick={() => setQuestionId(id !== questionId ? id : '')}
                                                    isActive={questionId === id}
                                                    key={`${id}`}
                                                    count={index}
                                                    title={title}
                                                    color={hotsite.theme.styles.data.faq.toggleIconColor}>
                                                    {text}
                                                </AccordionSummary>
                                            )
                                        })}
                                    </Accordion>
                                )
                            })}
                        </Container>
                    </Section>
                    <Patterns.Footer
                        theme={hotsite.theme.texts.data.themeName}
                        logo={`${location.filesPath(hotsite.theme.texts.data.header.headerLogo)}`}
                        backgroundColor={hotsite.theme.styles.data.footer?.footerBackgroundColor}
                        fontColor={hotsite.theme.styles.data.footer?.footerColor}
                        navData={menu(
                            hotsite.theme.texts.data.footer.footerNav,
                            '#como-funciona',
                            app.contactForm.data.existsTalkToUs,
                            hotsite.theme.texts.data.section1.section1Visibility,
                            hotsite.theme.texts.data.section1.section1Headline.section1HeadlineText,
                          )}
                        // navData={hotsite.theme.texts.data.section1.section1Visibility !== 'false' ? [
                        //     {
                        //         link: '#1',
                        //         title: hotsite.theme.texts.data.section1.section1Headline.section1HeadlineText
                        //     },
                        //     ...hotsite.theme.texts.data.footer.footerNav.slice(1)
                        // ] : hotsite.theme.texts.data.footer.footerNav.slice(1)}
                    />
                </>
            )}
            {notPublished && <Unpublished />}
        </Main>
    );
}

export { Hotsite };